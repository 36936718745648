import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchBoxComponent } from '@modules/shared/components/search-box/search-box.component';
import { SharedService } from '@services/shared/shared.service';
import { GiphyResponse } from '@models/shared/giphy-response.model';
import { UnsplashResponse } from '@models/shared/unsplash-response.model';
import { Asset, createDataUrl, emptyAsset } from '@models/shared/asset.model';
import { EventService } from '@services/events/event.service';
import { EventLinkBioService } from '@services/event-link-bio/event-link-bio.service';
import { EventLinkBio } from '@models/event-link-bio/event-link-bio.model';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-image-picker',
  standalone: true,
  imports: [CommonModule, MatTabsModule, TranslateModule, SearchBoxComponent],
  providers: [SharedService, EventService, EventLinkBioService],
  templateUrl: './image-picker.dialog.html',
  styleUrl: './image-picker.dialog.scss',
})
export class ImagePickerDialog {
  event?: any;
  eventImagePicker = false;
  selectedTabIndex = 0;
  eventLinkBio?: EventLinkBio;
  showGif: boolean = true;

  giphyRes?: GiphyResponse;
  unsplashRes?: UnsplashResponse;

  selectedImage?: Asset;

  imageTags = [
    'APP.IMAGE_TAGS.TRENDING',
    'APP.IMAGE_TAGS.BIRTHDAY',
    'APP.IMAGE_TAGS.WG_PARTY',
  ];
  selectedTag?: string;

  customImageAcceptedTypes = 'image/png,image/jpeg,image/gif';

  uploadInProgress = false;

  private readonly base64Prefix = 'base64,';

  constructor(
    public dialogRef: MatDialogRef<ImagePickerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private eventService: EventService,
    private eventLinkBioService: EventLinkBioService,
  ) {
    if (data) {
      if (data.event) {
        this.event = data.event;
      }

      if (data.eventLinkBio) {
        this.eventLinkBio = data.eventLinkBio;
        this.showGif = false;
      }

      this.eventImagePicker = data.eventImagePicker;

      if (data.gifsPreselected) {
        this.selectedTabIndex = 1;
      }

      if (data.loggedUser?.isB2B) {
        this.customImageAcceptedTypes =
          'image/png,image/jpeg,image/gif,video/mp4';
      }
    }

    this.onSearchTermChange('');
  }

  onSearchTermChange(searchTerm: string, isTag = false) {
    this.sharedService.getGiphyGifs(searchTerm).subscribe({
      next: (giphyRes) => {
        this.giphyRes = giphyRes;
      },
    });
    this.sharedService.getUnsplashImages(searchTerm).subscribe({
      next: (unsplashRes) => {
        this.unsplashRes = unsplashRes;
      },
    });

    if (!isTag && this.selectedTag) {
      this.selectedTag = undefined;
    }
  }

  toggleTag(tag: string) {
    if (this.selectedTag === tag) {
      this.selectedTag = undefined;
      this.onSearchTermChange('');
      return;
    }

    this.selectedTag = tag;
    this.onSearchTermChange(
      this.translateService.instant(this.selectedTag),
      true,
    );
  }

  uploadImage(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    this.handleFileChange(files[0]);
  }

  selectUnsplashImage(imageUrl: string) {
    if (!this.eventImagePicker) {
      this.dialogRef.close({ imageUrl: imageUrl });
      return;
    }

    if (this.event) {
      this.eventService
        .updateEvent(this.event?.id, {
          externalUrl: imageUrl,
          newUser: {
            eventUuid: this.event?.uuid,
          },
        })
        .subscribe((event) => (this.event = event));

      this.dialogRef.close({ event: this.event, imageUrl: imageUrl });
    }

    if (this.eventLinkBio) {
      this.eventLinkBioService
        .update(this.eventLinkBio.id, {
          customBackgroundExternalUrl: imageUrl,
          removeBackground: true,
        })
        .subscribe((eventLinkBio) => {
          this.eventLinkBio = eventLinkBio;
        });
      this.dialogRef.close({
        eventLinkBio: this.eventLinkBio,
        imageUrl: imageUrl,
      });
    }
  }

  selectGif(gifUrl: string) {
    if (!this.eventImagePicker) {
      this.dialogRef.close({ imageUrl: gifUrl });
      return;
    }

    this.eventService
      .updateEvent(this.event?.id, {
        externalUrl: gifUrl,
        newUser: {
          eventUuid: this.event?.uuid,
        },
      })
      .subscribe((event) => (this.event = event));

    this.dialogRef.close({ event: this.event, imageUrl: gifUrl });
  }

  private handleFileChange(file: File) {
    if (!file) {
      this.selectedImage = undefined;
      return;
    }
    if (!this.selectedImage) {
      this.selectedImage = emptyAsset();
    }
    this.selectedImage.name = file ? file.name : '';

    if (file && file.type) {
      this.selectedImage.mimeType = file.type;

      if (
        !this.customImageAcceptedTypes
          .split(',')
          .includes(this.selectedImage.mimeType)
      ) {
        return;
      }
    }

    this.loadImage(file);
  }

  private loadImage(file: File) {
    this.getBase64(file).then((base64: string | ArrayBuffer | null) => {
      if (!base64 || !this.selectedImage) {
        return;
      }

      base64 = base64.toString();
      base64 = base64.substring(
        base64.indexOf(this.base64Prefix) + this.base64Prefix.length,
      );

      this.selectedImage.base64 = base64;

      if (!this.eventImagePicker) {
        this.dialogRef.close({
          imageAsset: this.selectedImage,
        });
        return;
      }

      if (this.event) {
        this.uploadInProgress = true;
        this.eventService
          .updateEvent(this.event?.id, {
            mainPictureAsset: this.selectedImage,
            newUser: {
              eventUuid: this.event?.uuid,
            },
          })
          .pipe(finalize(() => (this.uploadInProgress = false)))
          .subscribe((event) => {
            this.event = event;
            this.dialogRef.close({
              event: this.event,
              imageUrl: this.event.mainPictureUrl,
            });
          });
      }

      if (this.eventLinkBio) {
        this.eventLinkBioService
          .update(this.eventLinkBio.id, {
            customBackgroundAsset: this.selectedImage,
          })
          .subscribe((eventLinkBio) => (this.eventLinkBio = eventLinkBio));
        this.dialogRef.close({
          eventLinkBio: this.eventLinkBio,
          imageUrl: createDataUrl(this.selectedImage),
        });
      }
    });
  }

  private getBase64(file: File) {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  close() {
    this.dialogRef.close();
  }
}
