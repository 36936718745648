import { Injectable } from '@angular/core';
import { EventLinkBioUpdateRequest } from '@models/event-link-bio/dto/event-link-bio-update.request';
import { EventLinkBio } from '@models/event-link-bio/event-link-bio.model';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { map } from 'rxjs/operators';
import { Event } from '@models/events/event.model';

@Injectable()
export class EventLinkBioService {
  constructor(private api: ApiService) {}

  create(uri: string): Observable<EventLinkBio> {
    return this.api
      .post(`${env.api}/events/link-bio`, { uri: uri })
      .pipe(map((it) => new EventLinkBio(it)));
  }

  getByUri(uri?: string): Observable<EventLinkBio> {
    return this.api
      .get(`${env.api}/events/link-bio?uri=${uri}`)
      .pipe(map((it) => new EventLinkBio(it)));
  }

  getLoggedUser(): Observable<EventLinkBio> {
    return this.api
      .get(`${env.api}/events/link-bio`)
      .pipe(map((it) => new EventLinkBio(it)));
  }

  checkUriExists(uri: string) {
    return this.api.get(`${env.api}/events/link-bio/uri-exists?uri=${uri}`);
  }
  update(
    id: number,
    request: EventLinkBioUpdateRequest,
  ): Observable<EventLinkBio> {
    return this.api
      .put(`${env.api}/events/link-bio/${id}`, request)
      .pipe(map((it) => new EventLinkBio(it)));
  }

  checkEventLinkBioUriExists(uri: string) {
    return this.api
      .get(`${env.api}/events/link-bio/uri-exists?uri=${uri}`)
      .pipe(map((it) => new EventLinkBio(it)));
  }
  getEvents(lsParams: Map<string, string>) {
    return this.api
      .get(`${env.api}/events/link-bio/events`, lsParams)
      .pipe(map(this.mapEvents));
  }

  private mapEvents(res: Event[]) {
    return res?.map((it) => new Event(it));
  }
}
