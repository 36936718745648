import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventRSVPOption } from '@models/event-attendance/event-rsvp-option.model';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventRSVPRequest } from '@models/events/dto/event-rsvp.request';
import { VerifyNoAccountRSVPRequest } from '@models/events/dto/verify-no-account-rsvp.request';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { LoginRequest } from '@models/users/dto/login.request';
import { NewUserCreationRequest } from '@models/users/dto/new-user-creation.request';
import { User } from '@models/users/user.model';
import { VerifyEmailDialogComponent } from '@modules/events/dialogs/verify-email-dialog/verify-email-dialog.component';
import { VerifyPhoneDialogComponent } from '@modules/events/dialogs/verify-phone-dialog/verify-phone-dialog.component';
import { CredentialsFormButtonToggleComponent } from '@modules/shared/components/credentials-form-button-toggle/credentials-form-button-toggle.component';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { VerifyEmailStepComponent } from '@modules/sign-up/components/verify-email-step/verify-email-step.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { UserService } from '@services/auth/user.service';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { FormsModules } from '@utils/shared-modules';
import { AppRoutes } from 'app/routes';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

@Component({
  selector: 'app-rsvp',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModules,
    MatCheckboxModule,
    TelInputComponent,
    VerifyEmailDialogComponent,
    VerifyPhoneDialogComponent,
    CredentialsFormButtonToggleComponent,
    VerifyEmailStepComponent,
    MatDialogActions,
  ],
  providers: [EventService, NotificationService, UserService, TranslateService],
  templateUrl: './rsvp.dialog.html',
  styleUrl: './rsvp.dialog.scss',
})
export class RsvpDialog implements OnInit {
  event?: Event;
  selectedRSVPType?: RSVPOptionType;
  isAttendee?: boolean;

  form?: FormGroup;

  loggedUser?: User;
  user?: User;
  userPhone?: string;
  loginRequest?: LoginRequest;

  numAttendeesOptions: number[] = [1];

  selectedOption: string = 'phone';

  step: number = 1;

  rsvpReq?: EventRSVPRequest;

  isWrongCode: boolean = false;

  otpUserId?: number;

  showPassword = false;

  onWaitlist: boolean = false;
  onPendingList: boolean = false;
  notGoing: boolean = false;
  rsvpOptions: EventRSVPOption[] | undefined = [];

  inProgress = false;
  inProgressWithoutAccount: boolean = false;

  accountWithEmailExists?: boolean;

  isNoAccountRSVPVerify = false;
  isNoAccountRSVPVerifyWrongCode = false;
  isSportPage: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RsvpDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private eventService: EventService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.event = data.event;
      this.selectedRSVPType = data.rsvpType;
      this.isAttendee = data.isAttendee;
      this.onWaitlist = data.onWaitlist;
      this.onPendingList = data.onPendingList;
      this.rsvpOptions = this.event?.rsvpOptions;
      this.notGoing = data.notGoing;

      this.authService.userSubject.subscribe((user) => {
        if (user.id > 0) {
          this.loggedUser = user;
        }

        if (this.event) {
          if (this.event.plusOnesLimit && this.event.plusOnesLimit > 1) {
            this.numAttendeesOptions = [
              ...Array(this.event.plusOnesLimit).keys(),
            ].map((x) => ++x);
          }

          let numAttendees = 0;

          if (this.loggedUser?.id) {
            const userAttendee = this.event.getUserAttendee(this.loggedUser.id);
            if (userAttendee) {
              numAttendees = userAttendee.getPlusOnes();
            }
          }

          this.form = this.fb.group({
            numAttendees: [
              {
                value: numAttendees,
                disabled: !this.event.plusOnesLimit,
              },
            ],
            comment: [''],
          });

          if (this.event.requireGroupRsvp) {
            const previousRSVP = this.event.getUserAttendee(
              this.loggedUser?.id || 0,
            );
            this.form.addControl(
              'groupName',
              this.fb.control(
                previousRSVP?.groupName || '',
                Validators.required,
              ),
            );
          }

          if (!this.loggedUser) {
            this.form.addControl(
              'name',
              this.fb.control('', Validators.required),
            );

            const emailFormGroup = this.fb.group({
              email: ['', [Validators.required, Validators.email]],
              password: ['', [Validators.required]],
              acceptedPrivacyPolicy: [false, [Validators.requiredTrue]],
              acceptedTerms: [false, [Validators.requiredTrue]],
            });

            this.form.addControl('emailFormGroup', emailFormGroup);
            this.form.addControl(
              'phoneFormGroup',
              this.fb.group({
                phone: ['', [Validators.required]],
              }),
            );

            this.emailFormGroup.controls['email'].valueChanges
              .pipe(
                debounceTime(250),
                distinctUntilChanged(),
                tap((val) => {
                  if (this.emailFormGroup.controls['email'].valid) {
                    this.checkUserWithEmailExists();
                  } else {
                    this.accountWithEmailExists = undefined;
                  }
                }),
              )
              .subscribe();
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.isSportPage = this.event?.type === EventType.SPORTPAGE;
  }

  get emailFormGroup(): FormGroup {
    return this.form?.get('emailFormGroup') as FormGroup;
  }

  get phoneFormGroup(): FormGroup {
    return this.form?.get('phoneFormGroup') as FormGroup;
  }

  close(event?: any) {
    this.dialogRef.close(event);
  }

  changePhone() {
    this.selectedOption = 'phone';
    this.step = 1;
  }

  selectOption(option: string): void {
    this.selectedOption = option;

    if (this.selectedOption === 'phone') {
      this.emailFormGroup?.controls['email'].reset();
      this.emailFormGroup?.controls['password'].reset();
    } else if (this.selectedOption === 'email') {
      this.phoneFormGroup?.controls['phone'].reset();
    }
  }

  onRSVPChange(rsvpType: RSVPOptionType) {
    this.selectedRSVPType = rsvpType;
  }

  rsvp() {
    if (!this.event || !this.form) {
      return;
    }

    this.isNoAccountRSVPVerify = false;

    if (
      (!this.loggedUser &&
        this.selectedOption === 'email' &&
        this.emailFormGroup.invalid) ||
      !this.selectedRSVPType
    ) {
      this.emailFormGroup.markAllAsTouched();
      return;
    } else if (
      (!this.loggedUser &&
        this.selectedOption === 'phone' &&
        this.phoneFormGroup.invalid) ||
      !this.selectedRSVPType
    ) {
      this.phoneFormGroup.markAllAsTouched();
      return;
    }

    const values = this.form.value;
    const phoneValues = this.phoneFormGroup?.value;
    const emailValues = this.emailFormGroup?.value;

    const req: EventRSVPRequest = {
      rsvpType: this.selectedRSVPType,
      numAttendees: values.numAttendees,
      comment: values.comment,
    };

    if (this.event.requireGroupRsvp) {
      req.groupName = values.groupName;
    }

    if (this.onWaitlist) {
      req.joinWaitlist = true;
    }

    const createOrFindUserReq: NewUserCreationRequest = {
      name: values.name,
    };

    if (!this.loggedUser) {
      if (this.selectedOption === 'email') {
        createOrFindUserReq.email = emailValues.email;
        createOrFindUserReq.password = emailValues.password;
      } else {
        createOrFindUserReq.phone = phoneValues.phone;
      }

      createOrFindUserReq.createAccount = true;
      createOrFindUserReq.eventId = this.event.id;
    }

    this.inProgress = true;

    if (!this.loggedUser && this.selectedOption === 'phone') {
      this.userService.createOrFindUser(createOrFindUserReq).subscribe({
        next: (userId: number) => {
          this.inProgress = false;
          this.otpUserId = userId;
          this.rsvpReq = req;
          this.userPhone = phoneValues.phone;
          this.step++;
        },
        error: () => {
          this.inProgress = false;
          this.notificationService.error(
            this.translateService.instant('APP.EVENT_RSVP.ERRORS.SIGN_UP'),
            this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
          );
        },
      });
    } else if (!this.loggedUser && this.selectedOption === 'email') {
      if (this.accountWithEmailExists) {
        this.rsvpReq = req;
        this.loginRequest = {
          email: emailValues.email,
          password: emailValues.password,
        };

        this.saveRSVP({
          req: this.rsvpReq,
          loginRequest: this.loginRequest,
        });
      } else {
        this.userService.createOrFindUser(createOrFindUserReq).subscribe({
          next: (userId: number) => {
            this.inProgress = false;
            this.otpUserId = userId;
            this.rsvpReq = req;
            this.loginRequest = {
              email: emailValues.email,
              password: emailValues.password,
            };
            this.step++;
          },
          error: () => {
            this.inProgress = false;
            this.notificationService.error(
              this.translateService.instant('APP.EVENT_RSVP.ERRORS.SIGN_UP'),
              this.translateService.instant(
                'APP.EVENT_RSVP.ERRORS.COULDNT_RSVP',
              ),
            );
          },
        });
      }
    } else {
      const previousRSVP = this.event.getUserAttendee(this.loggedUser?.id || 0);
      if (
        req.rsvpType !== previousRSVP?.rsvpType ||
        req.numAttendees !== previousRSVP?.getPlusOnes() ||
        req.groupName !== previousRSVP?.groupName
      ) {
        if (this.event?.isPreviewEvent) {
          this.inProgress = false;
          this.dialogRef.close({
            refresh: true,
            openEventAfterAttendOverlayDialog: true,
            rsvpType: req.rsvpType,
          });
          return;
        }

        this.eventService.saveRSVP(this.event?.id, req).subscribe({
          next: () => {
            this.inProgress = false;
            this.dialogRef.close({
              refresh: true,
              openEventAfterAttendOverlayDialog: true,
              rsvpType: req.rsvpType,
            });
          },
          error: (err) => {
            this.inProgress = false;
            this.checkAndShowRSVPError(err);
          },
        });
      } else {
        this.inProgress = false;
        this.dialogRef.close();
      }
    }
  }

  rsvpWithoutAccount() {
    if (!this.event || !this.form) {
      return;
    }

    if (
      (!this.loggedUser &&
        this.selectedOption === 'email' &&
        (this.emailFormGroup.controls['email'].invalid ||
          this.form.controls['name'].invalid)) ||
      !this.selectedRSVPType
    ) {
      this.form.controls['name'].markAsTouched();
      this.emailFormGroup.markAllAsTouched();
      return;
    }

    const values = this.form.value;
    const emailValues = this.emailFormGroup?.value;

    const req: EventRSVPRequest = {
      rsvpType: this.selectedRSVPType,
      numAttendees: values.numAttendees,
      comment: values.comment,
      email: emailValues.email,
    };

    if (this.event.requireGroupRsvp) {
      req.groupName = values.groupName;
    }

    if (this.onWaitlist) {
      req.joinWaitlist = true;
    }

    const createOrFindUserReq: NewUserCreationRequest = {
      name: values.name,
      email: emailValues.email,
      createAccount: false,
      eventId: this.event.id,
    };

    this.inProgressWithoutAccount = true;

    this.userService.createOrFindUser(createOrFindUserReq).subscribe({
      next: (userId: number) => {
        this.isNoAccountRSVPVerify = true;
        this.inProgressWithoutAccount = false;
        this.otpUserId = userId;
        this.rsvpReq = req;
        this.step++;
      },
      error: () => {
        this.inProgressWithoutAccount = false;
        this.notificationService.error(
          this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
        );
      },
    });
  }

  onNoAccountVerifyCodeCompleted(code: string) {
    if (!this.event || !this.rsvpReq) {
      return;
    }

    const req: VerifyNoAccountRSVPRequest = {
      otp: code,
      email: this.rsvpReq?.email,
      eventRSVPRequest: this.rsvpReq,
    };

    this.eventService.verifyNoAccountRSVP(this.event.id, req).subscribe({
      next: () => {
        this.dialogRef.close({
          refresh: true,
          openEventAfterAttendOverlayDialog: true,
          rsvpType: this.rsvpReq!.rsvpType,
        });
      },
      error: (err) => {
        if (err && err.error.startsWith('could not verify user')) {
          this.isNoAccountRSVPVerifyWrongCode = true;
        } else {
          this.checkAndShowRSVPError(err);
        }
      },
    });
  }

  saveRSVP(res: any) {
    if (res && this.event) {
      if (res.loginRequest) {
        if (res.loginRequest.email) {
          this.authService.login(
            res.loginRequest,
            false,
            () => {
              if (this.event?.isPreviewEvent) {
                this.dialogRef.close({
                  refresh: true,
                  openEventAfterAttendOverlayDialog: true,
                  rsvpType: res.req.rsvpType,
                });
                return;
              }

              this.eventService.saveRSVP(this.event?.id, res?.req).subscribe({
                next: () => {
                  this.dialogRef.close({
                    refresh: true,
                    openEventAfterAttendOverlayDialog: true,
                    rsvpType: res.req.rsvpType,
                  });
                },
                error: (err) => {
                  this.checkAndShowRSVPError(err);
                },
              });
            },
            (denied: any) => {
              this.inProgress = false;
              if (denied && denied.message) {
                if (denied.message === 'account_blocked') {
                  this.emailFormGroup.controls['password'].setErrors({
                    custom: this.translateService.instant(
                      'APP.AUTH.ERRORS.ACCOUNT_BLOCKED',
                    ),
                  });
                } else if (denied.message === 'unauthorized') {
                  this.emailFormGroup.controls['password'].setErrors({
                    custom: this.translateService.instant(
                      'APP.AUTH.ERRORS.WRONG_PASSWORD',
                    ),
                  });
                }
              } else {
                this.emailFormGroup.controls['password'].setErrors({
                  custom: this.translateService.instant(
                    'APP.AUTH.ERRORS.INVALID_EMAIL_OR_PASSWORD',
                  ),
                });
              }
            },
          );
        } else if (res.loginRequest.phone) {
          this.authService.loginWithOtp(
            res.loginRequest,
            false,
            () => {
              if (this.event?.isPreviewEvent) {
                this.dialogRef.close({
                  refresh: true,
                  openEventAfterAttendOverlayDialog: true,
                  rsvpType: res.req.rsvpType,
                });
                return;
              }

              this.eventService.saveRSVP(this.event?.id, res?.req).subscribe({
                next: () => {
                  this.dialogRef.close({
                    refresh: true,
                    openEventAfterAttendOverlayDialog: true,
                    rsvpType: res.req.rsvpType,
                  });
                },
                error: (err) => {
                  this.checkAndShowRSVPError(err);
                },
              });
            },
            () => {
              this.isWrongCode = true;
              this.notificationService.error(
                this.translateService.instant('APP.AUTH.ERRORS.INVALID_CODE'),
              );
            },
          );
        }
      }
    }
  }

  removeRSVP() {
    if (
      this.event &&
      this.event.id &&
      this.event.requireAttendeeApproval &&
      this.loggedUser &&
      this.loggedUser.id
    ) {
      const userAttendee = this.event.getUserAttendee(this.loggedUser.id);
      if (userAttendee) {
        this.eventService
          .deleteAttendee(this.event.id, userAttendee.id)
          .subscribe({
            next: () => {
              this.dialogRef.close({
                refresh: true,
                openEventAfterAttendOverlayDialog: false,
              });
            },
            error: (err) => {
              this.checkAndShowRSVPError(err);
            },
          });
      }
    }
  }

  onVerifyEmailSuccess() {
    this.saveRSVP({
      req: this.rsvpReq,
      loginRequest: this.loginRequest,
    });
  }

  checkUserWithEmailExists(): void {
    if (this.emailFormGroup.controls['email'].invalid) {
      return;
    }

    const email = this.emailFormGroup.controls['email'].value;

    let params: Map<string, any> = new Map<string, any>();
    params.set('isSignUp', true);

    this.authService.userOrAccountWithEmailExists(email, params).subscribe({
      next: (exists: boolean) => {
        if (!exists) {
          this.accountWithEmailExists = false;
          this.emailFormGroup.controls['acceptedPrivacyPolicy'].addValidators(
            Validators.requiredTrue,
          );
          this.emailFormGroup.controls['acceptedTerms'].addValidators(
            Validators.requiredTrue,
          );
        } else {
          this.accountWithEmailExists = true;
          this.emailFormGroup.controls[
            'acceptedPrivacyPolicy'
          ].clearValidators();
          this.emailFormGroup.controls['acceptedTerms'].clearValidators();
        }
        this.emailFormGroup.controls[
          'acceptedPrivacyPolicy'
        ].updateValueAndValidity();
        this.emailFormGroup.controls['acceptedTerms'].updateValueAndValidity();
      },
    });
  }

  goToForgotPassword(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/', AppRoutes.Root.forgotPassword]),
    );
    window.open(url, '_blank');
  }

  private checkAndShowRSVPError(err: any) {
    if (err?.error === 'bad request - max capacity exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.MAX_CAPACITY'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - rsvp disabled') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.DISABLED'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - plus ones limit exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.PLUS_ONES_LIMIT'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    }
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get yesRSVPOption(): EventRSVPOption | undefined {
    return this.event?.getYesRSVPOption();
  }

  get isSportpageEvent(): boolean {
    return this.event?.type === EventType.SPORTPAGE;
  }

  protected readonly requestAnimationFrame = requestAnimationFrame;
}
