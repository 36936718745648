export enum EventCampaignCategory {
  MOST_GUESTS = 'MOST_GUESTS',
  MOST_CREATIVE_CONCEPT = 'MOST_CREATIVE_CONCEPT',
  MOST_CONVINCING_APPLICATION = 'MOST_CONVINCING_APPLICATION',
}

export namespace EventCampaignCategory {
  export function getAllEventCampaignCategories(): EventCampaignCategory[] {
    return [
      EventCampaignCategory.MOST_GUESTS,
      EventCampaignCategory.MOST_CREATIVE_CONCEPT,
      EventCampaignCategory.MOST_CONVINCING_APPLICATION,
    ];
  }

  export function getDisplayName(category?: EventCampaignCategory): string {
    switch (category) {
      case EventCampaignCategory.MOST_GUESTS:
        return 'APP.CAMPAIGN.CATEGORY.MOST_GUESTS.TITLE';
      case EventCampaignCategory.MOST_CREATIVE_CONCEPT:
        return 'APP.CAMPAIGN.CATEGORY.MOST_CREATIVE_CONCEPT.TITLE';
      case EventCampaignCategory.MOST_CONVINCING_APPLICATION:
        return 'APP.CAMPAIGN.CATEGORY.MOST_CONVINCING_APPLICATION.TITLE';
      default:
        return '';
    }
  }
}
