@if (event && event.rsvpOptions) {
  @if (event.hasOneButtonSignUp() && yesRSVPOption) {
    @if (!editMode) {
      @if(currentUserRSVP === RSVPOptionType.YES){
        <button
          class="btn transparent-button"
          [class.attendee-view]="isAttendeeView"
          [ngStyle]="{
          'font-family': font?.bodyFont ? font?.bodyFont : ''
        }"
          (click)="onRSVP(RSVPOptionType.NO)"
        >
          @if (!isAttendeeView) {
            {{ yesRSVPOption.name | translate }}
          } @else {
            <i class="fal fa-check"></i> {{"APP.EVENTS.HAVE_REGISTERED" | translate}}
          }
        </button>
      }@else{
        <button
          class="btn transparent-button"
          [class.attendee-view]="isAttendeeView"
          [ngStyle]="{
          'font-family': font?.bodyFont ? font?.bodyFont : ''
        }"
          (click)="onRSVP(RSVPOptionType.YES)"
        >
          @if (!isAttendeeView) {
            {{ yesRSVPOption.name | translate }}
          } @else {
            {{ yesRSVPOption.name | translate }}
          }
        </button>
      }
    } @else {
      <div
        text-editable
        class="btn transparent-button"
        [ngStyle]="{
          'font-family': font?.bodyFont ? font?.bodyFont : ''
        }"
        maxlength="20"
        [ngModel]="yesRSVPOption.name | translate"
        (ngModelChange)="onRSVPOptionNameChanged($event, yesRSVPOption)"
      >
        {{ yesRSVPOption.name | translate }}
      </div>
    }
  }@else {
    <div class="rsvp-options">
      @for (rsvpOption of event.rsvpOptions; track rsvpOption.type) {
        @if (
          !userAttendeeRSVPOption ||
          userAttendeeRSVPOption.type === rsvpOption.type
        ) {
          <div class="rsvp-option-container">
            @if (
              getAttendees(rsvpOption.type) &&
              (showGuestCount || isLoggedUserHost)
            ) {
              <div
                class="attendees"
                (click)="viewAttendeeList(rsvpOption.type)"
                [ngxTippy]="!event.showGuestList ? tooltipTemplate : null"
                [tippyProps]="{
                  trigger: 'click'
                }"
              >
                @for (
                  attendee of getAttendees(rsvpOption.type, true);
                  track attendee.id
                ) {
                  <app-user-avatar
                    [user]="attendee.user"
                    [size]="28"
                    [placeholderFontSize]="12"
                    [fallbackInitials]="attendee.getFallbackInitials()"
                  ></app-user-avatar>
                }
                @if (getAttendeesLength(rsvpOption.type) > 3) {
                  <div
                    class="more-attendees"
                    [style.width.px]="28"
                    [style.height.px]="28"
                    [style.backgroundColor]="font?.primaryLogoFontColor"
                    [style.color]="
                      getTextColorForBg(font?.primaryLogoFontColor)
                    "
                  >
                    +{{ getAttendeesLength(rsvpOption.type) - 3 }}
                  </div>
                }
              </div>

              <ng-template #tooltipTemplate>
                <div>
                  {{ "APP.EVENT_RSVP.GUEST_LIST_DISABLED" | translate }}
                </div>
              </ng-template>
            }
            <div
              class="rsvp-option"
              [class.editable]="editMode"
              (click)="onRSVP(rsvpOption.type)"
            >
              <div
                class="rsvp-emoji"
                (click)="openEmojiPicker(rsvpOption.type)"
              >
                {{ rsvpOption.emoji }}
              </div>
              @if (!editMode) {
                <div
                  class="rsvp-name"
                  appTextSize
                  [size]="'small'"
                  [ngStyle]="{
                    'font-family': font?.bodyFont ? font?.bodyFont : ''
                  }"
                  [style.color]="font?.bodyFontColor"
                >
                  {{ rsvpOption.name | translate }}
                </div>
              } @else {
                <div
                  text-editable
                  class="rsvp-name"
                  [ngStyle]="{
                    'font-family': font?.bodyFont ? font?.bodyFont : ''
                  }"
                  [style.color]="font?.bodyFontColor"
                  maxlength="10"
                  [ngModel]="rsvpOption.name | translate"
                  (ngModelChange)="onRSVPOptionNameChanged($event, rsvpOption)"
                >
                  {{ rsvpOption.name | translate }}
                </div>
              }
            </div>
          </div>
        }
      }
      @if (showEmojiPicker) {
        <emoji-mart
          id="emoji-palette"
          class="emoji-picker"
          title="{{ 'APP.EVENT_EDIT.PICK_YOUR_EMOJI' | translate }}"
          [showPreview]="false"
          [perLine]="8"
          (emojiClick)="changeEmoji($event)"
        ></emoji-mart>
      }
    </div>
  }
}
