<div class="share-cont">
  <div
    class="share-buttons"
    [class.event-page-share-buttons]="isEventPage"
    [class.has-ticket]="ticket"
  >
    <div
      class="share-button"
      [class.theme-button]='themeColors'
      (click)="!editMode && share()"
      [ngxTippy]="editMode ? tooltipTemplate : null"
      [tippyProps]="{
        trigger: 'click',
        interactive: true
      }"
    >
      <i class="far fa-share"></i>
    </div>
    @if(showAddToCalendar){
    <div
      class="share-button"
      [class.theme-button]='themeColors'
      (click)="!editMode && addToCalendar()"
      [ngxTippy]="editMode ? tooltipTemplate : null"
      [tippyProps]="{
        trigger: 'click',
        interactive: true
      }"
    >
      <i class="far fa-calendar-day"></i>
    </div>
    }
    <div
      class="share-button"
      [class.theme-button]="themeColors"
      (click)="!editMode && copyLink()"
      [ngxTippy]="editMode ? tooltipTemplate : null"
      [tippyProps]="{
        trigger: 'click',
        interactive: true
      }"
    >
      <i class="far fa-link"></i>
    </div>
  </div>

  <ng-template #tooltipTemplate>
    <div class="preview-tooltip">
      {{ "APP.EVENT_EDIT.TRY_IT_OUT" | translate }}
      <span class="preview-button" (click)="share()">{{
        "APP.EVENT_EDIT.PREVIEW" | translate | lowercase
      }}</span>
      {{ "APP.EVENT_EDIT.MODE" | translate }}
    </div>
  </ng-template>

  @if (ticket) {
    <div
      class="btn ticket-action"
      [ngStyle]="{
        'font-family': font?.primaryLogoFont ? font?.primaryLogoFont : ''
      }"
      [style.backgroundColor]="font?.primaryLogoFontColor"
      [style.color]="getTextColorForBg(font?.primaryLogoFontColor)"
    >
      {{ "APP.EVENT.TICKET_BUY" | translate }}
      {{
        ticket.priceInCents / 100.0 | currency: "EUR" : "symbol-narrow" : ".0-2"
      }}
    </div>
  }
</div>
