import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Input,
  DoCheck,
} from '@angular/core';

@Directive({
  selector: '[appTextSize]',
  standalone: true,
})
export class TextSizeDirective implements AfterViewInit, OnChanges, DoCheck {
  @Input() appTextSize?: string;
  @Input() size?: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  contentLength?: number;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appTextSize'].currentValue !== '') {
      this.contentLength = changes['appTextSize'].currentValue.length;
      this.setFontSize();
    }
    if (changes['size']) {
      this.size = changes['size'].currentValue;
    }
  }

  ngDoCheck() {
    this.contentLength = this.el.nativeElement.textContent.length;
    this.setFontSize();
  }

  ngAfterViewInit(): void {
    this.contentLength = this.el.nativeElement.textContent.length;
    this.setFontSize();
  }

  private setFontSize() {
    const textLength = this.contentLength;
    let fontSize: string = '';

    if (this.size === 'x-small') {
      if (textLength) {
        if (textLength < 18) {
          fontSize = '11px';
        } else {
          fontSize = '8px';
        }
      }
    }
    if (this.size === 'small') {
      if (textLength) {
        if (textLength < 15) {
          fontSize = '14px';
        } else {
          fontSize = '13px';
        }
      }
    }

    if (this.size === 'medium') {
      if (textLength) {
        if (textLength < 15) {
          fontSize = '20px';
        } else if (textLength < 20) {
          fontSize = '16px';
        } else {
          fontSize = '10px';
        }
      }
    }
    if (this.size === 'large') {
      if (textLength) {
        if (textLength < 20) {
          fontSize = '21px';
        } else if (textLength < 31) {
          fontSize = '18px';
        } else {
          fontSize = '16px';
        }
      } else {
        this.setPlaceholderFontSize('21px');
      }
    }

    if (fontSize !== '') {
      this.renderer.setStyle(this.el.nativeElement, 'font-size', fontSize);
    }
  }

  private setPlaceholderFontSize(fontSize: string) {
    const placeholder = this.el.nativeElement.getAttribute('placeholder');
    if (placeholder && this.size === 'large') {
      this.renderer.setStyle(this.el.nativeElement, 'font-size', fontSize);
    }
  }
}
