import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-credentials-form-button-toggle',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './credentials-form-button-toggle.component.html',
  styleUrl: './credentials-form-button-toggle.component.scss',
})
export class CredentialsFormButtonToggleComponent {
  @Input() selectedOption?: string = 'phone';

  @Output() selectedOptionEvent = new EventEmitter<string>();

  selectOption(option: string): void {
    this.selectedOptionEvent.emit(option);
  }
}
