<div
  class="main-footer"
  [style.backgroundColor]="backgroundColor ? backgroundColor : ''"
>
  @if (showLogo && isDesktop) {
    @if (env.platform === "weddingpage.ai") {
      <div class="footer-powered-by-cont">
        <span>{{ "APP.POWERED_BY" | translate }}</span>
        <a class="footer-icon-a" routerLink="/">
          <img
            class="media-object footer-icon"
            [src]="env.iconAsset"
            alt="weddingpage.ai - Logo"
          />
        </a>
      </div>
    } @else {
      <a class="footer-brand" routerLink="/">
        <img
          class="media-object footer-logo"
          [src]="env.logoAsset"
          alt="eventpage.ai - Logo"
        />
      </a>
    }
  }
  @if (!isDesktop) {
    <div class="mobile-logo">
      <a class="footer-brand" routerLink="/">
        <img
          class="media-object footer-logo"
          [src]="env.logoAsset"
          alt="eventpage.ai - Logo"
        />
      </a>
      <div class="language-switcher">
        <button [matMenuTriggerFor]="appMenu" class="menu-button">
          <i class="fal fa-globe"></i>
          <span>{{ selectedLanguage | translate }}</span>
          <i class="far fa-chevron-down"></i>
        </button>
      </div>
    </div>
  }
  <ul class="widget-list">
    <li class="widget-item">
      <a href="https://eventpage.ai/imprint" target="_blank">{{
          "APP.IMPRINT" | translate
        }}</a>
    </li>

    <li class="widget-item">
      <a href="https://eventpage.ai/datenschutzerklaerung/" target="_blank">{{
          "APP.DATA_PRIVACY" | translate
        }}</a>
    </li>

    <li class="widget-item">
      <a href="https://eventpage.ai/terms-of-use/" target="_blank">{{
          "APP.TERMS_OF_USE" | translate
        }}</a>
    </li>

    <li class="widget-item">
      <a href="mailto:support@eventpage.ai" class="text-link">{{
          "APP.HELP" | translate
        }}</a>
    </li>
    @if (isDesktop) {
      <li class="widget-item">
        <button [matMenuTriggerFor]="appMenu" class="menu-button">
          <i class="fal fa-globe"></i>
          <span>{{ selectedLanguage | translate }}</span>
          <i class="far fa-chevron-down"></i>
        </button>
      </li>
    }
    <mat-menu #appMenu="matMenu" class="mat-menu-actions-container mb-1">
      @for (item of languageData; track $index) {
        <button mat-menu-item (click)="onMenuEvent(item.label)">
          {{ item.label | translate }}
        </button>
      }
    </mat-menu>
  </ul>
</div>
