import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { User } from '@models/users/user.model';
import { AuthService } from '@services/auth/auth.service';
import { DateAdapter } from '@angular/material/core';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, MatMenuModule],
  providers: [ScreenWidthService],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  @Input() showLogo = false;
  @Input() backgroundColor?: string;
  isDesktop?: boolean;
  loggedUser?: User;
  selectedLanguage: string = 'APP.LANGUAGE.ENGLISH';
  languageData = [
    {
      label: 'APP.LANGUAGE.ENGLISH',
      value: 'en',
    },
    {
      label: 'APP.LANGUAGE.GERMAN',
      value: 'de',
    },
  ];

  constructor(
    private screenWidthService: ScreenWidthService,
    private translateService: TranslateService,
    private authService: AuthService,
    private dateAdapter: DateAdapter<any>,
  ) {
    this.trackUserChanges();
    this.screenWidthService.isDesktop().subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });

    const currentLang = this.languageData.find(
      (languaage) => languaage.value === this.translateService.currentLang,
    );

    if (currentLang) {
      this.selectedLanguage = currentLang.label;
    }
  }

  onMenuEvent(languageSelected: string) {
    this.selectedLanguage = languageSelected;

    const selectedLangData = this.languageData.find(
      (languaage) => languaage.label === this.selectedLanguage,
    );
    if (selectedLangData) {
      if (this.loggedUser && this.loggedUser.id > 0) {
        this.authService
          .updateUser({
            id: this.loggedUser.id,
            language: selectedLangData.value,
          })
          .subscribe();
      } else {
        localStorage.setItem('userLang', selectedLangData.value);
      }

      this.translateService.use(selectedLangData.value);
      this.dateAdapter.setLocale(selectedLangData.value);
    }
  }

  private trackUserChanges() {
    this.authService.userSubject.subscribe((user) => {
      this.loggedUser = user;
    });
  }

  protected env = env;
}
