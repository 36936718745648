import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Event } from '@models/events/event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTicket } from '@models/events/event-ticket.model';
import { Helpers } from '@utils/helpers';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@services/shared/notification.service';
import { AddToCalendarDialog } from '@modules/shared/dialogs/add-to-calendar/add-to-calendar.dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { EventType } from '@models/events/event-type.enum';
import { EventLinkBio } from '@models/event-link-bio/event-link-bio.model';

@Component({
  selector: 'app-share-buttons',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgxTippyModule],
  providers: [NotificationService],
  templateUrl: './share-buttons.component.html',
  styleUrl: './share-buttons.component.scss',
})
export class ShareButtonsComponent {
  @Input() event?: Event;
  @Input() eventLinkBio?: EventLinkBio;
  @Input() isEventPage = false;
  @Input() showAddToCalendar: boolean = true;

  @Input() customToastSuccessClass?: string;
  @Input() editMode?: boolean = false;
  @Input() themeColors?: boolean = false;
  @Output() shareTabChanged = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  share() {
    if (this.event?.type === EventType.SPORTPAGE) {
      window.navigator.share({
        text: 'Mazuno Enerzy in the Dark Tour',
        title: `Mazuno Enerzy in the Dark Tour ${this.event.name}`,
        url: this.event.getLink(),
      });
    } else if (this.event && !this.editMode) {
      window.navigator.share({
        text: this.event.description,
        title: this.event.name,
        url: this.event.getLink(),
      });
    } else if (this.editMode) {
      this.shareTabChanged?.emit();
    } else if (this.eventLinkBio) {
      window.navigator.share({
        text: this.eventLinkBio.description,
        title: this.eventLinkBio.title,
        url: this.eventLinkBio.getLink(),
      });
    }
  }

  addToCalendar() {
    if (!this.editMode) {
      this.dialog.open(AddToCalendarDialog, {
        maxWidth: '602px',
        maxHeight: '100vh',
        width: '100%',
        height: 'auto',
        data: this.event,
        panelClass: ['normal-dialog', 'event-dialog', 'overlay-theme'],
      });
    } else {
      this.shareTabChanged?.emit();
    }
  }

  copyLink() {
    if (this.event && !this.editMode) {
      this.clipboard.copy(this.event?.getLink());
      this.notify();
    } else if (this.editMode) {
      this.shareTabChanged?.emit();
    } else if (this.eventLinkBio) {
      this.clipboard.copy(this.eventLinkBio?.getLink());
      this.notify();
    }
  }

  notify() {
    if (this.customToastSuccessClass && this.customToastSuccessClass !== '') {
      this.notificationService.successWithClass(
        this.translateService.instant(
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
        ),
        this.customToastSuccessClass,
      );
    } else {
      this.notificationService.success(
        this.translateService.instant(
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
        ),
      );
    }
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get ticket(): EventTicket | undefined {
    return undefined;
    // return this.event?.ticket;
  }

  get isInvitationEvent(): boolean {
    return this.event?.type === EventType.INVITATION;
  }

  getTextColorForBg = Helpers.getTextColorForBg;
}
