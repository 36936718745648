@if (event && form) {
  <div
    class="rsvp-dialog event-dialog-content"
    mat-dialog-content
    [formGroup]="form"
  >
    <div (click)="close()" class="close-button">
      <i class="fal fa-times-circle"></i>
    </div>
    <div *ngIf="step === 1">
      <div class="dialog-body">
        <div class="dialog-title mobile-only">
          @if (isAttendee) {
            {{ "APP.EVENT_RSVP.EDIT_TITLE" | translate }}
          } @else {
            {{ "APP.EVENT_RSVP.TITLE" | translate }}
          }
        </div>
        @if (onWaitlist) {
          <div class="waitlist-wrapper">
            <h1>{{ "APP.WAIT_LIST.JOIN_WAIT_LIST" | translate }}</h1>
            <span>{{ "APP.WAIT_LIST.WAIT_LIST_DESCRIPTION" | translate }}</span>
          </div>
        } @else if (onPendingList) {
          <div class="waitlist-wrapper">
            <h1>{{ "APP.WAIT_LIST.REQUEST_RSVP" | translate }}</h1>
            <span>{{
              "APP.WAIT_LIST.REQUEST_RSVP_DESCRIPTION" | translate
            }}</span>
          </div>
        } @else if(notGoing){
          <div class="waitlist-wrapper">
            <h1>{{ 'APP.EVENT_RSVP.CHANGED_YOUR_MIND' | translate }}</h1>
            <span>{{ 'APP.EVENT_RSVP.CHANGED_YOUR_MIND_DESCRIPTION' | translate }}</span>
          </div>
        }@else {
          <div class="rsvp-title-wrapper">
            <h1>{{ "APP.EVENT_RSVP.ARE_YOU_GOING" | translate }}</h1>
            <span>
              {{ "APP.EVENT_RSVP.DATA_SAFE_DESCRIPTION" | translate }}
            </span>
          </div>
        }
        @if (
          event.rsvpOptions &&
          !onWaitlist &&
          !onPendingList &&
          !event.hasOneButtonSignUp()
        ) {
          <div class="rsvp-options">
            @for (rsvpOption of rsvpOptions; track rsvpOption.type) {
              <div
                class="rsvp-option"
                [ngStyle]="{
                  opacity: selectedRSVPType === rsvpOption.type ? 1 : 0.45
                }"
                (click)="onRSVPChange(rsvpOption.type)"
              >
                <div class="rsvp-emoji">{{ rsvpOption.emoji }}</div>
                <div
                  class="rsvp-name"
                  [ngStyle]="{
                    'font-family': font?.primaryLogoFont
                      ? font?.primaryLogoFont
                      : ''
                  }"
                >
                  {{ rsvpOption.name | translate }}
                </div>
              </div>
            }
          </div>
        }

        <div>
          @if (!loggedUser && !event.rsvpWithoutEmail()) {
            <app-credentials-form-button-toggle
              [selectedOption]="selectedOption"
              (selectedOptionEvent)="selectOption($event)"
            >
            </app-credentials-form-button-toggle>
          }
          @if (event.requireGroupRsvp) {
            <mat-form-field
              appearance="outline"
              class="w-100"
              [class.has-value]="form.controls['groupName'].value !== ''"
            >
              <mat-label>{{
                "APP.EVENT_RSVP.TEAM_NAME" | translate
              }}</mat-label>
              <input type="text" matInput formControlName="groupName" />
            </mat-form-field>
          }
          @if (!loggedUser) {
            <mat-form-field
              appearance="outline"
              class="w-100"
              [class.has-value]="form.controls['name'].value !== ''"
            >
              <mat-label>{{ "APP.EVENT_RSVP.NAME" | translate }}</mat-label>
              <input type="text" matInput formControlName="name" />
            </mat-form-field>
          }

          <div class="flex-inputs-cont">
            @if (!loggedUser && selectedOption === "email") {
              <ng-container formGroupName="emailFormGroup">
                <mat-form-field
                  appearance="outline"
                  class="flex-input email-input"
                  [class.has-value]="
                    emailFormGroup.controls['email'].value !== ''
                  "
                >
                  <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
                  <input type="text" matInput formControlName="email" />
                </mat-form-field>
              </ng-container>
            }
            @if (!loggedUser && selectedOption === "phone") {
              <ng-container formGroupName="phoneFormGroup">
                <mat-form-field
                  class="w-100 phone-form"
                  [class.has-value]="
                    phoneFormGroup.controls['phone'].value !== ''
                  "
                  appearance="outline"
                >
                  <app-tel-input formControlName="phone"></app-tel-input>
                </mat-form-field>
              </ng-container>
            }

            <mat-form-field
              class="flex-input plus-ones-input"
              appearance="outline"
              [class.display-none]="
                selectedRSVPType === 'NO' || !event.plusOnesLimit
              "
            >
              <mat-select formControlName="numAttendees">
                <mat-option [value]="0">
                  {{ "APP.EVENT_RSVP.NO_PLUS_ONES" | translate }}
                </mat-option>
                <mat-option
                  *ngFor="let numAttendees of numAttendeesOptions"
                  [value]="numAttendees"
                  [class.has-value]="form.controls['numAttendees'].value !== ''"
                >
                  {{ "APP.EVENT_RSVP.PLUS" | translate }} {{ numAttendees }}
                  {{
                    (numAttendees === 1
                      ? "APP.EVENT_RSVP.ATTENDEE"
                      : "APP.EVENT_RSVP.ATTENDEES"
                    ) | translate
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          @if (!loggedUser && selectedOption === "email") {
            <div
              class="password-cont"
              [class.password-hidden]="accountWithEmailExists === undefined"
            >
              @if (accountWithEmailExists) {
                <div class="account-info">
                  {{ "APP.AUTH.RSVP_ACCOUNT_EXISTS" | translate }}
                </div>
              } @else if (accountWithEmailExists === false) {
                <div class="account-info">
                  {{ "APP.AUTH.RSVP_ACCOUNT_NOT_EXISTS" | translate }}
                </div>
              }

              <ng-container formGroupName="emailFormGroup">
                <mat-form-field
                  class="w-100"
                  [class.has-value]="
                    emailFormGroup.controls['password'].value !== ''
                  "
                  appearance="outline"
                  *ngIf="selectedOption === 'email'"
                >
                  <mat-label>{{ "APP.AUTH.PASSWORD" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    placeholder=""
                    type="{{ showPassword ? 'text' : 'password' }}"
                  />
                  <i
                    matSuffix
                    (click)="showPassword = !showPassword"
                    class="fal show-password-suffix"
                    [class.fa-eye]="!showPassword"
                    [class.fa-eye-slash]="showPassword"
                  ></i>
                  @if (emailFormGroup.controls["password"].hasError("custom")) {
                    <mat-error>
                      {{
                        emailFormGroup.controls["password"].getError("custom")
                      }}
                    </mat-error>
                  }
                </mat-form-field>
              </ng-container>

              @if (accountWithEmailExists) {
                <div class="auth-fallback-container account-info">
                  <span>{{ "APP.AUTH.FORGOT_PASSWORD" | translate }}</span
                  >&nbsp;
                  <span class="text-link" (click)="goToForgotPassword()">{{
                    "APP.AUTH.RESET_PASSWORD" | translate
                  }}</span>
                </div>
              } @else if (accountWithEmailExists === false) {
                <div class="terms-cont">
                  <div class="terms-cont-wrapper">
                    <ng-container formGroupName="emailFormGroup">
                      <mat-checkbox formControlName="acceptedTerms"
                        >{{ "APP.I_AGREE_TO" | translate }}
                        <a
                          href="https://eventpage.ai/terms-of-use/"
                          target="_blank"
                          >{{ "APP.THE_TERMS_OF_USE" | translate }}</a
                        ></mat-checkbox
                      >
                      <mat-checkbox formControlName="acceptedPrivacyPolicy"
                        >{{ "APP.I_CONSENT_TO" | translate }}
                        <a
                          href="https://eventpage.ai/datenschutzerklaerung/"
                          target="_blank"
                          >{{ "APP.THE_DATA_PRIVACY" | translate }}</a
                        ></mat-checkbox
                      >
                    </ng-container>
                  </div>
                </div>
              }
            </div>
          }

          <mat-form-field
            appearance="outline"
            class="w-100"
            [class.has-value]="form.controls['comment'].value !== ''"
          >
            <mat-label>{{
              (selectedRSVPType === "NO" ||
              onWaitlist ||
              onPendingList ||
              isSportpageEvent
                ? "APP.EVENT_RSVP.ADD_MESSAGE"
                : "APP.EVENT_RSVP.POST_COMMENT"
              ) | translate
            }}</mat-label>
            <input type="text" matInput formControlName="comment" />
          </mat-form-field>
        </div>
      </div>
      <div
        mat-dialog-actions
        class="dialog-actions"
        [class.rsvp-no-account]="
          !loggedUser &&
          event.hasOneButtonSignUp() &&
          selectedOption === 'email' &&
          accountWithEmailExists === false
        "
      >
        <button
          class="btn btn-primary"
          (click)="rsvp()"
          [class.spinner]="inProgress"
          [disabled]="inProgress"
        >
          @if (isAttendee) {
            @if(notGoing){
              {{ "APP.EVENT_RSVP.UNSUBSCRIBE" | translate }}
            }
            @else{
              {{ "APP.EVENT_RSVP.UPDATE_RESPONSE" | translate }}
            }
          } @else {
            @if (event.hasOneButtonSignUp() && yesRSVPOption) {
              {{ "APP.AUTH.JOIN_NOW" | translate }}
            } @else {
              {{ "APP.CONTINUE" | translate }}
            }
          }
        </button>
        @if (
          !loggedUser &&
          event.hasOneButtonSignUp() &&
          selectedOption === "email" &&
          accountWithEmailExists === false
        ) {
          @if(isSportPage){
            <button
              class="btn btn-primary-outline white-outline"
              (click)="rsvpWithoutAccount()"
              [disabled]="inProgressWithoutAccount"
            >
              {{ "APP.AUTH.RSVP_WITHOUT_ACCOUNT" | translate }}
            </button>
          }@else{
            <div class="text-link" (click)="rsvpWithoutAccount()">
              {{ "APP.AUTH.RSVP_WITHOUT_ACCOUNT" | translate }}
            </div>
          }
        }
      </div>
    </div>

    <div *ngIf="step === 2">
      <div *ngIf="selectedOption === 'phone'">
        <app-verify-phone-dialog
          [otpUserId]="otpUserId"
          [phone]="userPhone"
          [eventUuid]="event.uuid"
          [eventId]="event.id"
          [rsvpReq]="rsvpReq"
          [isRSVPwithOTP]="true"
          [isWrongCode]="isWrongCode"
          (changePhone)="changePhone()"
          (isSaveRSVPEvent)="saveRSVP($event)"
        >
        </app-verify-phone-dialog>
      </div>

      <!--    <div *ngIf="selectedOption === 'email'">-->
      <!--      <app-verify-email-dialog-->
      <!--        [user]="user"-->
      <!--        [eventUuid]="event.uuid"-->
      <!--        [isAtPublish]="true"-->
      <!--        (isAtPublishEvent)="close($event)"-->
      <!--      >-->
      <!--      </app-verify-email-dialog>-->
      <!--    </div>-->

      @if (
        selectedOption === "email" && (loginRequest || isNoAccountRSVPVerify)
      ) {
        <app-verify-email-step
          [email]="loginRequest ? loginRequest.email : rsvpReq?.email"
          [userId]="otpUserId"
          [isDialogVerify]="true"
          [isNoAccountRSVPVerify]="isNoAccountRSVPVerify"
          [isWrongCode]="isNoAccountRSVPVerifyWrongCode"
          (verifyEmailSuccess)="onVerifyEmailSuccess()"
          (noAccountVerifyCodeCompleted)="
            onNoAccountVerifyCodeCompleted($event)
          "
          class="auth-step-page-center"
        />
      }
    </div>
  </div>
}
