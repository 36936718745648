<div class="event-hosts">
  @if (event && font) {
    @if (!multipleHosts) {
      <div class="single-host">
        <app-user-avatar
          [user]="event.hostUser"
          [size]="40"
          [nameTooltip]="multipleHosts"
          [isVotingView]="isVotingView"
          [placeholderFontSize]="16"
        ></app-user-avatar>
        <div class="host-name body-font">
          @if (isVotingView) {
            {{ event.hostUser?.getInitials() }}
          } @else {
            {{ event.hostUser?.name }}
          }
        </div>
      </div>
    } @else if (multipleHosts) {
      <div [class.multiple-hosts]="multipleHosts">
        <div class="hosts">
          <div class="event-host">
            <app-user-avatar
              [user]="event.hostUser"
              [size]="40"f
              [nameTooltip]="multipleHosts"
              [isVotingView]="isVotingView"
              [placeholderFontSize]="16"
            ></app-user-avatar>
          </div>
          @if (multipleHosts) {
            @for (host of event.hosts; track host.id) {
              @if (editMode || host.status === "ACTIVE") {
                <div class="event-host">
                  @if (editMode) {
                    @if (host.status === "INVITED") {
                      <i class="fa fa-clock host-invite-pending-icon"></i>
                    } @else {
                      <div class="remove-btn" (click)="removeHost(host)">
                        <i class="far fa-times"></i>
                      </div>
                    }
                  }

                  <app-user-avatar
                    [user]="host.user"
                    [size]="40"
                    [nameTooltip]="true"
                    [style.height.px]="40"
                    [placeholderFontSize]="16"
                    [isVotingView]="isVotingView"
                  ></app-user-avatar>
                </div>
              }
            }
          }
          @if (editMode) {
            <div class="event-host add-host" (click)="addHost()">
              <div class="add-host-placeholder">
                <i class="far fa-plus"></i>
              </div>
            </div>
          }
        </div>
      </div>
    }
  }
</div>
