import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteFriendsComponent } from '@modules/events/components/invite-friends/invite-friends.component';
import { TranslateModule } from '@ngx-translate/core';
import { ShareButtonsComponent } from '@modules/shared/components/share-buttons/share-buttons.component';
import { AffiliateProductCardComponent } from '@modules/admin/affiliates-shared/components/affiliate-product-card/affiliate-product-card.component';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FooterComponent } from 'app/components/footer/footer.component';
import { DesignTemplateCardComponent } from '@modules/shared/components/design-template-card/design-template-card.component';
import { Event } from '@models/events/event.model';
import { EventAffiliateProductType } from '@models/events/event-affiliate-product-type.enum';
import { EventTemplateService } from '@services/events/event-template.service';
import { EventTemplateCardData } from '@models/design-templates/event-template-card-data.model';
import { AppRoutes } from 'app/routes';
import { Router } from '@angular/router';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventType } from '@models/events/event-type.enum';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';

@Component({
  selector: 'app-event-after-attend-overlay-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ShareButtonsComponent,
    AffiliateProductCardComponent,
    DesignTemplateCardComponent,
    FooterComponent,
    InviteFriendsComponent,
  ],
  providers: [EventTemplateService],
  templateUrl: './event-after-attend-overlay-dialog.component.html',
  styleUrl: './event-after-attend-overlay-dialog.component.scss',
})
export class EventAfterAttendOverlayDialogComponent {
  wishlistProducts: AffiliateProduct[] = [];
  affiliateProducts: AffiliateProduct[] = [];
  shoppingListAffiliateProduct?: AffiliateProduct;
  designTemplates: EventTemplateCardData[] = [];

  event?: Event;
  rsvpType: RSVPOptionType = RSVPOptionType.YES;
  onWaitlist: boolean = false;
  onPendingList: boolean = false;
  canInviteFellows: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EventAfterAttendOverlayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventTemplateService: EventTemplateService,
    private router: Router,
  ) {
    if (data?.event) {
      this.rsvpType = data.rsvpType;
      this.event = data.event;
      this.onWaitlist = data.onWaitlist;
      this.onPendingList = data.onPendingList;
      this.canInviteFellows = this.event?.canAttendeeInviteMutuals
        ? this.event?.canAttendeeInviteMutuals
        : false;
      if (this.event?.eventTemplate) {
        this.wishlistProducts =
          this.event.eventTemplate.getAfterAffiliateProducts(
            EventAffiliateProductType.AFTER_ATTEND_GIFT,
          );

        this.affiliateProducts =
          this.event.eventTemplate.getAfterAffiliateProducts(
            EventAffiliateProductType.AFTER_ATTEND,
          );

        const shoppingListAffiliateProductArr =
          this.event.eventTemplate.getAfterAffiliateProducts(
            EventAffiliateProductType.AFTER_ATTEND_SHOPPING_LIST,
          );

        this.shoppingListAffiliateProduct =
          shoppingListAffiliateProductArr?.length > 0
            ? shoppingListAffiliateProductArr[0]
            : undefined;
      }

      this.eventTemplateService.fetch().subscribe({
        next: (templates) => {
          this.designTemplates = templates;
        },
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  moreWishlist() {}

  moreAffiliateProducts() {
    if (this.shoppingListAffiliateProduct) {
      window.open(this.shoppingListAffiliateProduct.link, '_blank');
    }
  }

  createEvent() {
    this.router.navigate(['/', AppRoutes.Events.createEvent]);
    this.close();
  }

  onTemplateChosen(templateId: number) {
    this.router.navigate(['/', AppRoutes.Events.createEvent], {
      queryParams: { templateId: templateId },
    });
    this.close();
  }

  followCreator() {}

  goToPartypageInsta() {
    window.open('https://www.instagram.com/partypage.ai/', '_blank');
  }

  get isWeddingpageEvent(): boolean {
    return this.event?.type === EventType.WEDDINGPAGE;
  }

  get isInvitationEvent(): boolean {
    return this.event?.type === EventType.INVITATION;
  }

  get isSportpageEvent(): boolean {
    return this.event?.type === EventType.SPORTPAGE;
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get afterScreenRsvpType(): RSVPOptionType {
    if (this.rsvpType === RSVPOptionType.NO) {
      return this.rsvpType;
    }

    if (this.onPendingList) {
      return RSVPOptionType.PENDING_APPROVAL;
    } else if (this.onWaitlist) {
      return RSVPOptionType.WAITLIST;
    }

    return this.rsvpType;
  }

  get rsvpOptionAfterScreenTitle(): string | undefined {
    return this.event?.getRsvpConfirmationTextByType(this.afterScreenRsvpType)
      ?.title;
  }

  get afterScreenDescription(): string | undefined {
    return this.event?.getRsvpConfirmationTextByType(this.afterScreenRsvpType)
      ?.description;
  }

  get afterScreenBackgroundImage(): string {
    return this.isSportpageEvent
      ? encodeURI(
          'https://static.eventpage.ai/event-templates/event-templates-1485/after-rsvp-yes-bg.jpg',
        )
      : '';
  }

  protected readonly RSVPOptionType = RSVPOptionType;
}
