@if (event && visual && font) {
  <div class="page-container">
    @if (visual.patternUrl) {
      <img class="pattern" [src]="visual.patternUrl" />
    }
    <div class="event-container" [class.host-view]="isHostView">
      <div class="party-action-container">
        @if (!eventActionsSticky) {
          @if (event.mainPictureUrl || visual.keyVisual1) {
            <div class="main-picture">
              @if (event.isMainPictureVideo()) {
                <video
                  class="key-visual-1-video"
                  [muted]="'muted'"
                  autoplay
                  playsinline
                  loop
                >
                  <source [src]="event.mainPictureUrl" type="video/mp4" />
                </video>
              } @else {
                <img
                  class="key-visual-1"
                  [src]="event.mainPictureUrl || visual.keyVisual1?.url"
                />
              }
              @if (
                event.hasCampaignLogo() &&
                event.eventCampaign?.campaign?.logoUrl
              ) {
                <div>
                  <img
                    class="campaign-logo"
                    [src]="event.eventCampaign?.campaign?.logoUrl"
                  />
                </div>
              }
              @if (isCampaign && campaignData) {
                <app-campaign-ranking
                  [campaignData]="campaignData"
                  [event]="event"
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                />
              }
            </div>
          }
          @if (!isVotingView && !isCohostInvitation) {
            @if (isAttendeeView) {
              @if (event.hasOneButtonSignUp()) {
                <div class="event-actions attendee-view">
                  <app-event-rsvp
                    [loggedUserId]="loggedUser?.id"
                    [isLoggedUserHost]="
                      loggedUser &&
                      loggedUser.id &&
                      (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                    "
                    [showGuestCount]="event.showGuestCount"
                    [isAttendeeView]="isAttendeeView"
                  ></app-event-rsvp>
                  <app-share-buttons
                    [event]="event"
                    [isEventPage]="true"
                  ></app-share-buttons>
                </div>
              } @else {
                <div class="event-actions attendee-view">
                  <div class="share-button" (click)="addToCalendar()">
                    <i class="far fa-calendar-day"></i>
                  </div>
                  <app-event-rsvp
                    [loggedUserId]="loggedUser?.id"
                    [isLoggedUserHost]="
                      loggedUser &&
                      loggedUser.id &&
                      (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                    "
                    [showGuestCount]="event.showGuestCount"
                    [isAttendeeView]="isAttendeeView"
                  ></app-event-rsvp>
                  <div class="share-button" (click)="shareEvent()">
                    <i class="far fa-share"></i>
                  </div>
                </div>
              }
            } @else if (!event.maxCapacityReached()) {
              @if (event.requireAttendeeApproval) {
                @if (userOnPendingList) {
                  <button class="btn transparent-button disabled-icon-button">
                    <i class="far fa-clock icon"></i>
                    {{ "APP.WAIT_LIST.PENDING_APPROVAL" | translate }}
                  </button>
                } @else if (event.canRsvp()) {
                  <button
                    class="btn transparent-button"
                    (click)="getOnPendingList()"
                  >
                    {{ "APP.WAIT_LIST.GET_ON_THE_LIST" | translate }}
                  </button>
                }
              } @else if (
                event.canRsvp() || isUserHost || loggedUser?.isAdmin()
              ) {
                <div
                  class="event-actions"
                  [class.one-button-actions]="event.hasOneButtonSignUp()"
                >
                  <app-event-rsvp
                    [isLoggedUserHost]="
                      loggedUser &&
                      loggedUser.id &&
                      (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                    "
                    [showGuestCount]="event.showGuestCount"
                  ></app-event-rsvp>
                  <app-share-buttons
                    [event]="event"
                    [isEventPage]="true"
                  ></app-share-buttons>
                </div>
              }
            } @else if (event.maxCapacityReached() && event.waitlistEnabled) {
              @if (userOnWaitList) {
                <button class="btn transparent-button disabled-icon-button">
                  <i class="far fa-clock icon"></i>
                  {{ "APP.WAIT_LIST.JOINED_WAIT_LIST" | translate }}
                </button>
              } @else if (event.canRsvp()) {
                <button class="btn transparent-button" (click)="joinWaitlist()">
                  {{ "APP.WAIT_LIST.JOIN_WAIT_LIST" | translate }}
                </button>
              }
            }
          }
        }
      </div>

      <div class="party-info-container">
        @if (
          eventActionsSticky && (event.mainPictureUrl || visual.keyVisual1)
        ) {
          <div class="main-picture-cont">
            <div class="main-picture">
              @if (event.isMainPictureVideo()) {
                <video
                  class="key-visual-1-video"
                  [muted]="'muted'"
                  autoplay
                  playsinline
                  loop
                >
                  <source [src]="event.mainPictureUrl" type="video/mp4" />
                </video>
              } @else {
                <img
                  class="key-visual-1"
                  [src]="event.mainPictureUrl || visual.keyVisual1?.url"
                />
              }
              @if (
                event.hasCampaignLogo() &&
                event.eventCampaign?.campaign?.logoUrl
              ) {
                <div>
                  <img
                    class="campaign-logo"
                    [src]="event.eventCampaign?.campaign?.logoUrl"
                  />
                </div>
              }
              @if (isCampaign && campaignData) {
                <app-campaign-ranking
                  [campaignData]="campaignData"
                  [event]="event"
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                />
              }
            </div>
          </div>
        }
        @if (event.subHeader && event.subHeader !== "") {
          <div
            class="invitation-text body-font"
            [style.color]="visual.backgroundColor ? visual.backgroundColor : ''"
          >
            {{ event.subHeader }}
          </div>
        }

        <div
          class="event-name primary-font"
          [style.font-weight]="
            font.primaryFontWeight ? font.primaryFontWeight : null
          "
        >
          {{ event.name }}
        </div>

        <div class="event-date-loc-info body-font">
          @if (event.startDate) {
            <div class="event-date-cont">
              <div class="event-date event-info-with-icon">
                <i class="far fa-calendar-day"></i>
                <span [class.blurry]="isVotingView">{{
                  formatDateDay(event.startDate, currentLang) +
                    "., " +
                    (event.startDate | date: "dd.MM.yyyy")
                }}</span>
              </div>
              <div class="event-time event-info-with-icon">
                <i class="far fa-clock"></i>
                <span [class.blurry]="isVotingView">{{
                  event.startDate | date: "HH:mm"
                }}</span>
                @if (event.endsOnSameDate()) {
                  <span [class.blurry]="isVotingView"
                    >- {{ event.endDate | date: "HH:mm" }}</span
                  >
                }
              </div>
              @if (event.endDate && !event.endsOnSameDate()) {
                <span [class.blurry]="isVotingView">-</span>
              }
              @if (event.endDate && !event.endsOnSameDate()) {
                <div class="event-date-cont">
                  <div class="event-date event-info-with-icon">
                    <i class="far fa-calendar-day"></i>
                    <span [class.blurry]="isVotingView">{{
                      formatDateDay(event.endDate, currentLang) +
                        "., " +
                        (event.endDate | date: "dd.MM.yyyy")
                    }}</span>
                  </div>
                  <div class="event-time event-info-with-icon">
                    <i class="far fa-clock"></i>
                    <span [class.blurry]="isVotingView">{{
                      event.endDate | date: "HH:mm"
                    }}</span>
                  </div>
                </div>
              }
            </div>
          } @else {
            <div class="event-date-cont">
              <div class="event-date event-info-with-icon">
                <i class="far fa-calendar-day"></i>
                <span [class.blurry]="isVotingView">{{
                  "APP.EVENT.DATE_TBD" | translate
                }}</span>
              </div>
            </div>
          }
          <!--          @if (event.endDate && !event.endsOnSameDate()) {-->
          <!--            <div class="event-date-cont">-->
          <!--              <div class="event-date event-info-with-icon">-->
          <!--                <i class="far fa-calendar-day"></i>-->
          <!--                <span [class.blurry]="isVotingView">{{-->
          <!--                    formatDateDay(event.endDate, currentLang)-->
          <!--                    + '., ' + (event.endDate | date: "dd.MM.yyyy")-->
          <!--                }}</span>-->
          <!--              </div>-->
          <!--              <div class="event-time event-info-with-icon">-->
          <!--                <i class="far fa-clock"></i>-->
          <!--                <span [class.blurry]="isVotingView">{{-->
          <!--                  event.endDate | date: "HH:mm"-->
          <!--                }}</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          }-->
          @if (event.hasAddress()) {
            <a
              class="event-address event-info-with-icon"
              [class.inactive-link]="isVotingView"
              [href]="event.getGoogleMapsLink()"
              target="_blank"
            >
              <i class="far fa-map-pin"></i>
              <span [class.blurry]="isVotingView">{{
                event.getAddressDisplayName()
              }}</span>
            </a>
          } @else {
            <div class="event-date-cont">
              <div class="event-address event-info-with-icon">
                <i class="far fa-map-pin"></i>
                <span [class.blurry]="isVotingView">{{
                  "APP.EVENT.LOCATION_TBD" | translate
                }}</span>
              </div>
            </div>
          }
        </div>

        <div class="body-text body-font" [innerText]="event.description"></div>

        <div class="hosts-section">
          <div class="hosts-title body-font">
            {{ "APP.EVENT.HOSTED_BY" | translate }}
          </div>
          <app-event-hosts
            [event]="event"
            [isVotingView]="isVotingView"
          ></app-event-hosts>
        </div>

        @if (eventActionsSticky && !isVotingView && !isCohostInvitation) {
          @if (isAttendeeView) {
            @if (event.hasOneButtonSignUp()) {
              <div class="event-actions attendee-view">
                <app-event-rsvp
                  [loggedUserId]="loggedUser?.id"
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                  [showGuestCount]="event.showGuestCount"
                  [isAttendeeView]="isAttendeeView"
                ></app-event-rsvp>
                <app-share-buttons
                  [event]="event"
                  [isEventPage]="true"
                ></app-share-buttons>
              </div>
            } @else {
              <div class="event-actions attendee-view">
                <div class="share-button" (click)="addToCalendar()">
                  <i class="far fa-calendar-day"></i>
                </div>
                <app-event-rsvp
                  [loggedUserId]="loggedUser?.id"
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                  [showGuestCount]="event.showGuestCount"
                  [isAttendeeView]="isAttendeeView"
                ></app-event-rsvp>
                <div class="share-button" (click)="shareEvent()">
                  <i class="far fa-share"></i>
                </div>
              </div>
            }
          } @else if (!event.maxCapacityReached()) {
            @if (event.requireAttendeeApproval) {
              @if (userOnPendingList) {
                <button class="btn transparent-button disabled-icon-button">
                  <i class="far fa-clock icon"></i>
                  {{ "APP.WAIT_LIST.PENDING_APPROVAL" | translate }}
                </button>
              } @else if (event.canRsvp()) {
                <button
                  class="btn transparent-button"
                  (click)="getOnPendingList()"
                >
                  {{ "APP.WAIT_LIST.GET_ON_THE_LIST" | translate }}
                </button>
              }
            } @else if (event.canRsvp() || isUserHost) {
              <div class="event-actions">
                <app-event-rsvp
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                  [showGuestCount]="event.showGuestCount"
                ></app-event-rsvp>
              </div>
              <app-share-buttons
                [event]="event"
                [isEventPage]="true"
                class="share-buttons-standalone"
              ></app-share-buttons>
            }
          } @else if (event.maxCapacityReached() && event.waitlistEnabled) {
            @if (userOnWaitList) {
              <div class="event-actions">
                <button class="btn transparent-button disabled-icon-button">
                  <i class="far fa-clock icon"></i>
                  {{ "APP.WAIT_LIST.JOINED_WAIT_LIST" | translate }}
                </button>
              </div>
            } @else if (event.canRsvp()) {
              <div class="event-actions">
                <button class="btn transparent-button" (click)="joinWaitlist()">
                  {{ "APP.WAIT_LIST.JOIN_WAIT_LIST" | translate }}
                </button>
              </div>
            }
          }
        }

        @if (!isServer && widgets && widgets.length > 0 && !isVotingView) {
          <mat-tab-group mat-stretch-tabs="false" class="event-widget-tabs">
            @if (widgets.includes(EventWidget.COMMENTS)) {
              <mat-tab label="{{ 'APP.COMMENTS.TITLE' | translate }}">
                <app-event-comments
                  [loggedUser]="loggedUser"
                ></app-event-comments>
              </mat-tab>
            }
          </mat-tab-group>
        }
      </div>
    </div>

    <app-footer
      class="after-footer"
      [showLogo]="true"
      [backgroundColor]="
        visual.footerColor !== ''
          ? visual.footerColor
          : 'rgba(255, 255, 255, 0.7)'
      "
    ></app-footer>
  </div>

  <div
    class="background-overlay"
    [class.host-view]="isHostView"
    [style.backgroundColor]="
      visual.backgroundColor ? visual.backgroundColor : ''
    "
    [style.background-image]="
      visualBackgroundUrl && visual.isBackgroundImage()
        ? 'url(' + visualBackgroundUrl + ')'
        : ''
    "
  >
    @if (visualBackgroundUrl && visual.isBackgroundVideo()) {
      <div class="background-video-cont">
        <video
          #backgroundVideo
          class="background-video"
          [muted]="'muted'"
          playsinline
          loop
          preload="metadata"
        >
          @if (visualBackgroundHevcUrl !== "") {
            <source
              [src]="visualBackgroundHevcUrl + '#t=0.001'"
              type="video/mp4;codecs=hvc1"
            />
          }
          <source
            [src]="visualBackgroundUrl"
            [type]="visual.background?.mimeType || 'video/mp4'"
          />
        </video>
      </div>
    }
  </div>

  @if (visualEffectUrl) {
    <div
      class="effect-overlay"
      [style.background-image]="
        visualEffectUrl && visual.isEffectImage()
          ? 'url(' + visualEffectUrl + ')'
          : ''
      "
      [class.host-view]="isHostView"
      [class.one-time-effect]="!visual.effect?.repeat"
    >
      @if (!visual.isEffectImage()) {
        <video
          #effectVideo
          class="effect-video"
          [muted]="'muted'"
          playsinline
          [loop]="visual.effect?.repeat"
          preload="metadata"
        >
          @if (visualEffectHevcUrl !== "") {
            <source
              [src]="visualEffectHevcUrl + '#t=0.001'"
              type="video/mp4;codecs=hvc1"
            />
          }
          <source
            [src]="visualEffectUrl"
            [type]="visual.effect?.mimeType || 'video/mp4'"
          />
        </video>
      }
    </div>
  }
}
